class Scroll {
  constructor() {
    this.init();
  }

  init() {
    jQuery('.js-scroll').on('click', (e) => {
      jQuery('.js-menu-switch').removeClass('is-active');
      let target = jQuery(e.currentTarget).attr('href');
      console.log(target);
      if (target == '#') {
        target = 'html';
      }

      this.move(jQuery(target));
      return false;

    });
  }

  move(target) {
    jQuery("html,body").animate({
      scrollTop: jQuery(target).offset().top - 120
    }, 500);
  }
}

export default Scroll;
