import Scroll from './Scroll';
import Parallax from './parallax';

$(function ($) {
  new Scroll();
  new Parallax();
  $('.js-slideMain').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots:true,
    autoplay:'auto',
    fade: true,
    autoplaySpeed: 4000,
    speed: 2000,
    pauseOnFocus: false,//フォーカスで一時停止
    pauseOnHover: false,//マウスホバーで一時停止
    pauseOnDotsHover: false//ドットナビをマウスホバーで一時停止
  });

  $('.jc-acSticth').on('click',(e)=>{
    const $target=$(e.currentTarget);
    $target.toggleClass('is-open');
  })

  $('.js-menuSwitch').on('click',function(){
    $('html').toggleClass('is-menu');
  })

  $(".js-youtube").colorbox({iframe:true, innerWidth:'90%', innerHeight:'40%', maxWidth:"90%",
  maxHeight:"90%"});

  var $pcHeader = $('.js-header');
  $(window).on('scroll load resize', function () {
    var top = $(window).scrollTop();
    if (100 < top) {
      $pcHeader.addClass('is-active');
    } else {
      $pcHeader.removeClass('is-active');
    }
  });
});
